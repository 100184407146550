import GATSBY_COMPILED_MDX from "/vercel/path0/src/pages/projects/mdx/yildiz-sarayi-cit-kasri.mdx";
import * as React from 'react';
import Layout from '/src/components/layout';
import Seo from '/src/components/seo';
import {Link, graphql} from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Carousel} from 'react-bootstrap';
import {mainImage, container, carouselContainer, header, link, name, headerElement, carouselCaption, carousel} from '../../components/css/project-page.module.css';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
const prepareCaption = caption => {
  let wordArr = caption.split('-');
  let returnArr = wordArr.map(word => word.slice(0, 1).toUpperCase() + word.slice(1) + ' ');
  return returnArr;
};
const Project = ({data, children}) => {
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.name
  }, React.createElement("div", {
    className: mainImage
  }, React.createElement(GatsbyImage, {
    image: getImage(data.mdx.frontmatter.main),
    alt: data.mdx.frontmatter.name
  })), React.createElement("div", {
    className: container
  }, React.createElement("div", {
    className: carouselContainer
  }, React.createElement("div", {
    className: header
  }, React.createElement(Link, {
    to: "/projects/",
    className: link
  }, "< Projeler"), React.createElement("p", {
    className: name
  }, data.mdx.frontmatter.name), React.createElement("div", {
    className: headerElement
  }, React.createElement("h3", null, "Lokasyon"), React.createElement("h4", null, data.mdx.frontmatter.location)), React.createElement("div", {
    className: headerElement
  }, React.createElement("h3", null, "Kurum/Müşteri"), React.createElement("h4", null, data.mdx.frontmatter.related_org)), React.createElement("div", {
    className: headerElement
  }, React.createElement("h3", null, "Tarih"), React.createElement("h4", null, data.mdx.frontmatter.date)), React.createElement("div", {
    className: headerElement
  }, React.createElement("h3", null, "İş Türü"), React.createElement("h4", null, data.mdx.frontmatter.job_descp))), React.createElement("div", {
    className: carousel
  }, React.createElement(Carousel, {
    variant: "dark",
    style: {
      display: "inline-block",
      width: "100%",
      height: "auto"
    },
    keyboard: "true",
    pause: "hover",
    touch: "ture"
  }, data.file.edges.map(({node}) => node.name === 'main' ? null : React.createElement(Carousel.Item, {
    key: node.id
  }, React.createElement(GatsbyImage, {
    image: getImage(node),
    alt: ""
  }), React.createElement(Carousel.Caption, {
    className: carouselCaption
  }, prepareCaption(node.name))))))), React.createElement("h5", null, children)));
};
export const projectQuery = graphql`
query ($id: String, $frontmatter__slug: String) {
  mdx:mdx(id: {eq: $id}) {
    frontmatter {
      name
      date(formatString: "YYYY")
      location
      slug
      related_org
      job_descp
      main {
        childImageSharp {
          gatsbyImageData(quality: 100,  
                          layout: FULL_WIDTH, 
                          placeholder: BLURRED,
                          transformOptions: {fit: COVER})
        }
      }
    }
  }
  file:allFile(filter: {relativeDirectory: {eq: $frontmatter__slug}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData (height:600
            placeholder: BLURRED
            blurredOptions: {width: 100}
            quality: 70
            transformOptions: {fit: CONTAIN, cropFocus:CENTER}
            layout: CONSTRAINED
            aspectRatio:1.7)
        }
        name
      }
    }
  }
}`;
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.name + " | " + "Projeler"
});
Project
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Project, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
